/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        //textille
        
        $(".textille").hover(
          function () {
            $(this).addClass("textille_hover");
            var $tlt = $(this).textillate({ 
              autoStart: false,
              in: { effect: 'tada' },
               type: 'word',
              callback: function () {
                  $(this).removeClass("textille_hover");
                }

              });

              $tlt.on('mouseenter', function (){$tlt.textillate('start'); });
              // $tlt.on('mouseout', function (){
              //   $tlt.textillate('stop'); 
              // });
          },
          function () {
            $(this).removeClass("textille_hover");
          }
          );
        //add clas all images
        $('img').addClass('img-responsive');
        //flowtpy
        $('body').flowtype({
         minimum   : 500,
         maximum   : 1200,
         minFont   : 12,
         maxFont   : 16,
         fontRatio : 20
        });
        $('.navbar-brand').flowtype({
         minimum   : 500,
         maximum   : 1200,
         minFont   : 12,
         maxFont   : 16,
         fontRatio : 20
        });
        //Google Fonts
         WebFontConfig = {
            google: { families: [ 'Roboto:500,300,700,400:latin,latin-ext' ] }
          };
          (function() {
            var wf = document.createElement('script');
            wf.src = '//ajax.googleapis.com/ajax/libs/webfont/1/webfont.js';
            // wf.src = ('https:' == document.location.protocol ? 'https' : 'http') +
            //   '://ajax.googleapis.com/ajax/libs/webfont/1/webfont.js';
            wf.type = 'text/javascript';
            wf.async = 'true';
            var s = document.getElementsByTagName('script')[0];
            s.parentNode.insertBefore(wf, s);
          })();
          //afix
          $('#aff').affix({
              offset: {
                top: 200,
                bottom: function () {
                  return (this.bottom = $('footer').outerHeight(true));
                }
              }
            });
          
      }, 
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        $(".btn-compartir").click(function(e){
           var $social_pie = $(this).next(".social-pie");
            $social_pie.toggle('slow');
            $(".social-pie").not($social_pie).slideUp(); //hide the others
            e.preventDefault();
        });
        //boton back to the top
        if ($('#back-to-top').length) {
            var scrollTrigger = 300, // px
                backToTop = function () {
                    var scrollTop = $(window).scrollTop();
                    if (scrollTop > scrollTrigger) {
                        $('#back-to-top').addClass('show');
                    } else {
                        $('#back-to-top').removeClass('show');
                    }
                };
            backToTop();
            $(window).on('scroll', function () {
                backToTop();
            });
            $('#back-to-top').on('click', function (e) {
                e.preventDefault();
                $('html,body').animate({
                    scrollTop: 0
                }, 700);
            });
        }
       
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        
          
       
        
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
        $('#finiscarousel').carousel();
 


        //Masonry
        
         $(window).load(function(){
                var container = document.querySelector('.main');
                var msnry = new Masonry( container, {
                  
                  columnWidth:'.post-sizer',
                  gutter: '.gutter-sizer',
                  itemSelector: '.post',
                  percentPosition: true

                });
              });
        
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'category_sesiones':{
      finalize: function(){
       //Masonry
         $(window).load(function(){
                var container = document.querySelector('.main');
                var msnry = new Masonry( container, {
                  itemSelector: '.post',
                  columnWidth:'.post',
                  gutter: '.gutter-sizer',
                  percentPosition: true

                });
             
          //hasta aqui
        }); 
      }

    },
    'category':{
      finalize: function(){
       //Masonry
         $(window).load(function(){
                var container = document.querySelector('.main');
                var msnry = new Masonry( container, {
                  itemSelector: '.post',
                  columnWidth:'.post-sizer',
                  gutter: '.gutter-sizer',
                  percentPosition: true

                });
             
          //hasta aqui
        }); 
      }

    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
